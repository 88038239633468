import React, { useState } from 'react';
import Consultar from './components/consultar';
import Presentar from './components/presentar';

function MainContent() {

  //-----------------Gestión formularios--------------------
  const [activeTab, setActiveTab] = useState('presentar');

  //Función para activar/desactivar formularios
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <React.Fragment>
        <div id= "contenedor" className='w-full mx-auto bg-white'>
            <div id="tarjeta" className="p-12 sm:w-full md:w-2/3 lg:2/4 xl:2/4 2xl:2/4 mx-auto md:mt-14 mt-0 md:mb-10 mb-0 rounded bg-background shadow-md">
                <ul id="tabs" className="flex w-full px-1 pt-2 border-b-2 justify-center sm:justify-start">
                  <li 
                    className={`px-4 py-2 -mb-px font-semibold text-gray-400 ${
                      activeTab === 'consultar' ? 'border-customRed border-b-2 text-gray-700' : 'border-transparent'
                    }`}
                  >
                      <a id="consultar-tab" href="#" onClick={(e) => {handleTabClick('consultar')}}>Consultar</a>
                  </li>
                  <li 
                    className={`px-4 py-2 font-semibold text-gray-400 rounded-t ${
                      activeTab === 'presentar' ? 'border-customRed border-b-2 text-gray-700' : 'border-transparent'
                    }`}
                  >
                      <a id="presentar-tab" href="#" onClick={() => handleTabClick('presentar')}>Presentar</a>
                  </li>
                </ul>
                <div id="tab-contents">
                  {activeTab === 'consultar' && <Consultar/>}
                  {activeTab === 'presentar' && <Presentar/>}
                </div>
            </div>
        </div>        

    </React.Fragment>
  );
}

export default MainContent;
