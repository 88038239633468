import React from 'react';

const Footer = () => {

    return (
        <React.Fragment>
            <footer>
                
            </footer>
        </React.Fragment>
    );
}

export default Footer;