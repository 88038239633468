import React, { useState, useRef } from 'react';
import CryptoJS from 'crypto-js';
import ReCAPTCHA from 'react-google-recaptcha';
import Loading from '../../components/Loading';
import Denuncia from './denuncia';
import { BiSolidUser, BiIdCard , BiGroup, BiPhone, BiMailSend, BiCalendar, BiBell, BiSolidLandmark, BiSolidMessageRoundedDots, BiFile } from "react-icons/bi";

const Presentar = () => {

    //-----------------Componente loading---------------
    const [loading, setLoading] = useState(false);
    //-----------------Gestión datos--------------------
    const [anonimo, setAnonimo] = useState(false);
    const [selectedFile, setSelectedFile] = useState('');
    const [denuncia, setDenuncia] = useState(''); 
    const [nombre, setNombre] = useState('');
    const [apellidos, setApellidos] = useState('');
    const [dni, setDNI] = useState('');
    const [relacion, setRelacion] = useState('');
    const [mail, setMail] = useState('');
    const [mail2, setMail2] = useState('');
    const [sameMail, setSameMail] = useState(true);
    const [telf, setTelf] = useState('');
    const [fecha, setFecha] = useState('');
    const [notificar, setNotificar] = useState('');
    const [comision, setComision] = useState('');
    const [fichero, setFichero] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [hash, setHash] = useState('');
    //-----------------Validamos datos formulario------
    const [validated, setValidated] = useState(false);
    const [warning, setWarning] = useState(false);       
    //----------------Tamaño textarea------------------
    const textAreaRef = useRef(null);

    //Reseteamos el formulario de presentar denuncia
    const resetDenuncia = () => {
        setDenuncia(null);
        resetForm();
      };

    //Reseteamos todos los estados del formulario
    const resetForm = () => {
        setAnonimo(false);
        setSelectedFile('');
        setNombre('');
        setApellidos('');
        setDNI('');
        setRelacion('');
        setMail('');
        setMail2('');
        setSameMail(true);
        setTelf('');
        setFecha('');
        setNotificar('');
        setComision('');
        setFichero('');
        setDescripcion('');
        setHash('');
        setValidated(false);
        setWarning(false);
    };

    //Función del recaptcha
    const handleCaptchaChange = (value) => {
        console.log("Captcha value:", value);
        // Puedes manejar la respuesta del reCAPTCHA aquí
    };

    //Función que se encarga de manejar el input file
    const handleInputChange = () => {
        const textarea = textAreaRef.current;
        textarea.style.height = 'auto'; // Restablecer la altura a automática para que el textarea se ajuste correctamente
        textarea.style.height = `${textarea.scrollHeight}px`; // Establecer la altura del textarea para que coincida con el contenido
      };

    //Función que se encarga de leer el fichero
    const handleFile = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file ? file.name : null);
        const reader = new FileReader();

        reader.onloadend = () => {
            const fileURL = reader.result;
            setFichero(fileURL);
        };

        if(file){
            reader.readAsDataURL(file);
        }
    };

    //Función que se encarga de generar un id único
    const hashGenerator = () => {
        // Generar un valor único combinando la fecha actual y un número aleatorio
        const uniqueValue = new Date().toISOString() + Math.random().toString(36).substring(7);
        // Generar el hash SHA256 del valor único
        const hash = CryptoJS.SHA256(uniqueValue).toString(CryptoJS.enc.Hex);
        // Tomar la mitad del hash
        const halfHash = hash.substring(0, hash.length / 6);
        setHash(halfHash);
    }

    //Función que se encarga de manejar el formulario
    const submitHandler = (event) => {
        event.preventDefault();
        //Activamos el loading
        setLoading(true);
        const form = event.currentTarget;
        //Comprobamos si los emails introducidos coinciden, si no
        if(mail !== mail2){
            //No pasa la validación del email
            setSameMail(false);
            setWarning(true);
            setValidated(false);
            setLoading(false);
            return;
        } else {
            //Pasa la validación del email
            setSameMail(true);
        }

        //Si el formulario no pasa todas las validaciones
        if(form.checkValidity() === false){
            setWarning(true);
            event.preventDefault();
            event.stopPropagation();
            setLoading(false);
            return;
        } else {
            //Si el formulario para todas las validaciones
            setWarning(false);
            setValidated(true);
            event.preventDefault();
            hashGenerator();

            //Transformamos el estado anonimo true/false al valor que tiene que tener para que se lea correctamente
            let ceder;            
            if(anonimo === true) ceder = "on";
            if(anonimo === false) ceder = "off";

            //Creamos el objeto para enviarlo a la API
            var payload = {
                fecha: fecha,
                informar: notificar,
                descripcion: descripcion,
                fichero: fichero,
                ceder: ceder,
                comision: comision,
                nombre: nombre,
                apellidos: apellidos,
                dni: dni,
                relacion: relacion,
                telf: telf,
                mail: mail,
                hash: hash
            }     

            //Enviamos el objeto a la API para su creación
            try {
                fetch('https://dev-backend.fcf.services/compliance/put', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Error al enviar el formulario');
                    }
                    return response.json();
                })
                //Si se ha enviado correctamente
                .then(data => {
                    if (data.success) {
                        const hashFromAPI = data.result.hash;
                        setDenuncia(hashFromAPI);
                        setLoading(false);
                    } else {
                        console.log('Error al enviar el formulario');
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });                
            } catch (error) {
                console.error('Error:', error);
            }
    
        }

    };

  return (
    <React.Fragment>
        <div className='mt-6'>
            <h2 className='font-bebas text-2xl mb-6 tracking-wide text-gray-700'>Presentar comunicació d'infracció</h2>
            { (denuncia) ? <Denuncia denuncia={denuncia} resetDenuncia={resetDenuncia}/> : 
                <form noValidate validated={validated.toString()} onSubmit={submitHandler}>
                <div className='flex flex-col w-full sm:w-auto'>
                    <div id="contacte" >
                        <h3 className='font-bebas text-xl mb-6 tracking-wide text-gray-700'>Dades bàsiques de contacte</h3>
                        <div id="anonimo" className='flex flex-row'>
                            <label
                                className="relative flex cursor-pointer items-center rounded-full p-3"
                                htmlFor="checkanonimo"
                                data-ripple-dark="true"
                                >
                                <input
                                    id="checkanonimo"
                                    type="checkbox"
                                    onChange={(e) => setAnonimo(e.target.checked)}
                                    className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-gray-400 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-customRed checked:bg-customRed checked:before:bg-customRed hover:before:opacity-10"
                                />
                                <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
                                    <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-3.5 w-3.5"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    stroke="currentColor"
                                    strokeWidth="1"
                                    >
                                    <path
                                        fillRule="evenodd"
                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                        clipRule="evenodd"
                                    ></path>
                                    </svg>
                                </div>
                                </label>
                                <label
                                className="mt-px pt-2 cursor-pointer select-none font-light text-gray-700"
                                htmlFor="checkanonimo"
                                >
                                Desitjo comunicar una infracció de forma anònima.
                            </label>                    
                        </div>
                    {
                        (anonimo === true) ?  <></> :
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:gap-4">
                        
                            <div id="nom" className="my-2 w-full sm:w-auto justify-center flex items-center rounded-md">
                                <div>
                                    <div
                                        className="flex items-center bg-gray-100 rounded-l-md border border-white justify-center w-12 h-12 text-gray-400 ">
                                        <BiSolidUser />
                                    </div>
                                </div>

                                <div className="w-full">
                                    <input type="text" x-model="input1" 
                                        required
                                        value={nombre}
                                        onChange={(e) => setNombre(e.target.value)}
                                        className={`w-full h-12 px-4 py-1 rounded-r-md border border-gray-100 text-gray-800 focus:outline-none focus:shadow-lg focus:shadow-slate-200 duration-100 shadow-gray-100 ${warning && nombre ==='' && 'border-red-500'}`}
                                        placeholder="Nom" />
                                </div>
                            </div>
                            <div id="cognoms" className="my-2 w-full sm:w-auto justify-center flex items-center rounded-md">
                                <div>
                                    <div
                                        className="flex items-center bg-gray-100 rounded-l-md border border-white justify-center w-12 h-12 text-gray-400 ">
                                        <BiSolidUser />
                                    </div>
                                </div>

                                <div className="w-full">
                                    <input type="text" x-model="input1" 
                                        required
                                        value={apellidos}
                                        onChange={(e) => setApellidos(e.target.value)}
                                        className={`w-full h-12 px-4 py-1 rounded-r-md border border-gray-100 text-gray-800 focus:outline-none focus:shadow-lg focus:shadow-slate-200 duration-100 shadow-gray-100 ${warning && apellidos ==='' && 'border-red-500'}`}
                                        placeholder="Cognoms" />
                                </div>
                            </div>
                            <div id="dni" className="my-2 w-full sm:w-auto justify-center flex items-center rounded-md">
                                <div>
                                    <div
                                        className="flex items-center bg-gray-100 rounded-l-md border border-white justify-center w-12 h-12 text-gray-400 ">
                                        <BiIdCard />
                                    </div>
                                </div>

                                <div className="w-full">
                                    <input type="text" x-model="input1" 
                                        required
                                        value={dni}
                                        onChange={(e) => setDNI(e.target.value)}
                                        className={`w-full h-12 px-4 py-1 rounded-r-md border border-gray-100 text-gray-800 focus:outline-none focus:shadow-lg focus:shadow-slate-200 duration-100 shadow-gray-100 ${warning && dni ==='' && 'border-red-500'}`}
                                        placeholder="Document d'identitat" />
                                </div>
                            </div>
                            <div id="relacio" className="my-2 w-full sm:w-auto justify-center flex items-center rounded-md">
                                <div>
                                    <div
                                        className="flex items-center bg-gray-100 rounded-l-md border border-white justify-center w-12 h-12 text-gray-400 ">
                                        <BiGroup  />
                                    </div>
                                </div>

                                <div className="w-full">
                                    <input type="text" x-model="input1" 
                                        required
                                        value={relacion}
                                        onChange={(e) => setRelacion(e.target.value)}
                                        className={`w-full h-12 px-4 py-1 rounded-r-md border border-gray-100 text-gray-800 focus:outline-none focus:shadow-lg focus:shadow-slate-200 duration-100 shadow-gray-100 ${warning && relacion ==='' && 'border-red-500'}`}
                                        placeholder="Relació/Vinculació amb la FCF" />
                                </div>
                            </div>
                            <div id="correu" className="my-2 w-full sm:w-auto justify-center flex items-center rounded-md">
                                <div>
                                    <div
                                        className="flex items-center bg-gray-100 rounded-l-md border border-white justify-center w-12 h-12 text-gray-400 ">
                                        <BiMailSend  />
                                    </div>
                                </div>

                                <div className="w-full">
                                    <input type="email" x-model="input1" 
                                        required
                                        value={mail}
                                        onChange={(e) => setMail(e.target.value)}
                                        className={`w-full h-12 px-4 py-1 rounded-r-md border border-gray-100 text-gray-800 focus:outline-none focus:shadow-lg focus:shadow-slate-200 duration-100 shadow-gray-100 ${(warning && mail ==='') || !sameMail ? 'border-red-500' : ''}`}
                                        placeholder="Correu electrònic" />
                                </div>
                            </div>
                            <div id="confirmcorreu" className="my-2 w-full sm:w-auto justify-center flex items-center rounded-md">
                                <div>
                                    <div
                                        className="flex items-center bg-gray-100 rounded-l-md border border-white justify-center w-12 h-12 text-gray-400 ">
                                        <BiMailSend  />
                                    </div>
                                </div>

                                <div className="w-full">
                                    <input type="email" x-model="input1" 
                                        required
                                        value={mail2}
                                        onChange={(e) => setMail2(e.target.value)}
                                        className={`w-full h-12 px-4 py-1 rounded-r-md border border-gray-100 text-gray-800 focus:outline-none focus:shadow-lg focus:shadow-slate-200 duration-100 shadow-gray-100 ${(warning && mail ==='') || !sameMail ? 'border-red-500': ''}`}
                                        placeholder="Confirmar correu electrònic" />
                                </div>
                            </div>
                            <div id="telefon" className="my-2 w-full sm:w-auto justify-center flex items-center rounded-md">
                                <div>
                                    <div
                                        className="flex items-center bg-gray-100 rounded-l-md border border-white justify-center w-12 h-12 text-gray-400 ">
                                        <BiPhone  />
                                    </div>
                                </div>

                                <div className="w-full">
                                    <input type="text" x-model="input1" 
                                        required
                                        value={telf}
                                        onChange={(e) => setTelf(e.target.value)}
                                        className={`w-full h-12 px-4 py-1 rounded-r-md border border-gray-100 text-gray-800 focus:outline-none focus:shadow-lg focus:shadow-slate-200 duration-100 shadow-gray-100 ${warning && telf ==='' && 'border-red-500'}`}
                                        placeholder="Telèfon" />
                                </div>
                            </div>
                        </div>                    
                        }
                        {
                            sameMail === false && <p className='text-red-500 mt-5'>Hi ha un error a la confirmació del correu electrònic</p>
                        }
                    </div>



                    <div className='mt-10' id="infraccio">
                        <h3 className='font-bebas text-xl mb-6 tracking-wide text-gray-700'>Dades sobre la comunicació d'infracció</h3>
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:gap-4">                        
                            <div id="data" className="my-2 w-full sm:w-auto justify-center flex-row items-center rounded-md ">
                                <div className='flex items-center w-full'>    
                                    <div>
                                        <div
                                            className="flex items-center bg-gray-100 rounded-l-md border border-white justify-center w-12 h-12 text-gray-400 ">
                                            <BiCalendar />
                                        </div>
                                    </div>
                                    <div className="w-full">
                                        <input type="date" x-model="input1" 
                                            required
                                            value={fecha}
                                            onChange={(e) => setFecha(e.target.value)}
                                            className={`w-full h-12 px-4 py-1 rounded-r-md border border-gray-100 text-gray-800 focus:outline-none focus:shadow-lg focus:shadow-slate-200 duration-100 shadow-gray-100 ${warning && fecha ==='' && 'border-red-500'}`}
                                        />                                
                                    </div>
                                </div>
                                <div className='w-full sm:mt-0 ml-12 text-gray-700'>
                                    <small>Data aproximada dels fets</small>
                                </div>
                            </div>

                            <div id="info" className="my-2 w-full sm:w-auto justify-center flex-row items-center rounded-md">
                                <div className='flex items-center w-full'>
                                    <div>
                                        <div
                                            className="flex items-center bg-gray-100 rounded-l-md border border-white justify-center w-12 h-12 text-gray-400 ">
                                            <BiBell />
                                        </div>
                                    </div>

                                    <div className="w-full">
                                        <select required 
                                        value={notificar}
                                        onChange={(e) => setNotificar(e.target.value)}
                                        className={`w-full h-12 px-4 py-1 rounded-r-md border border-gray-100 text-gray-800 focus:outline-none focus:shadow-lg focus:shadow-slate-200 duration-100 shadow-gray-100 ${warning && notificar ==='' && 'border-red-500'}`}>
                                            <option value="" disabled defaultValue hidden>Seleccionar</option>
                                            <option value="Si">Si</option>
                                            <option value="No">No</option>
                                        </select>                                
                                    </div>
                                </div>
                                <div className='w-full sm:mt-0 ml-12 text-gray-700'>
                                    <small>Vol rebre informació sobre la tramitació de la comunicació?</small>
                                </div>
                            </div>
                            <div id="file" className="my-2 w-full sm:w-auto justify-center flex items-center rounded-md">
                                <div>
                                    <div
                                        className="flex items-center bg-gray-100 rounded-l-md border border-white justify-center w-12 h-12 text-gray-400 ">
                                        <BiFile />
                                    </div>
                                </div>

                                <div className="w-full">
                                    <input type="file" accept="image/jpeg,image/png,application/pdf" id="fileInput" className="hidden" onChange={handleFile} />
                                    <label htmlFor="fileInput" className="cursor-pointer w-full h-12 px-4 py-1 rounded-r-md border border-gray-100 text-gray-800 flex items-center justify-between focus:outline-none focus:shadow-lg focus:shadow-slate-200 duration-100 shadow-gray-100 bg-white">
                                    {selectedFile ? <span >{selectedFile ? selectedFile : 'Seleccionar fitxer'}</span> : <span className='text-gray-400'>Seleccionar fitxer</span>}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div id="descripcio" className="my-2 w-full sm:w-auto justify-center flex items-center rounded-md">
                            <div>
                                <div
                                    className="flex items-center bg-gray-100 rounded-l-md border border-white justify-center w-12 h-12 text-gray-400 ">
                                    <BiSolidMessageRoundedDots />
                                </div>
                            </div>

                            <div className="w-full">
                                <textarea 
                                    ref={textAreaRef}
                                    required 
                                    value={descripcion}
                                    onChange={(e) => {
                                        setDescripcion(e.target.value);
                                        handleInputChange();
                                    }}
                                    placeholder="Descripció dels fets"
                                    className={`w-full mt-3 h-12 px-4 py-1 rounded-r-md border border-gray-100 text-gray-800 focus:outline-none focus:shadow-lg focus:shadow-slate-200 duration-100 shadow-gray-100" rows="3" ${warning && descripcion ==='' && 'border-red-500'}`}
                                />
                            </div>
                        </div>
                    </div>

                    <div id="aceptar" className='flex flex-row mt-10'>
                        <label
                              className="relative flex cursor-pointer items-center rounded-full p-3"
                              htmlFor="checkaceptar"
                              data-ripple-dark="true"
                            >
                              <input
                                id="checkaceptar"
                                type="checkbox"
                                required
                                className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-gray-400 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-customRed checked:bg-customRed checked:before:bg-customRed hover:before:opacity-10"
                              />
                              <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-3.5 w-3.5"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  stroke="currentColor"
                                  strokeWidth="1"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                              </div>
                            </label>
                            <label
                              className="mt-px pt-2 cursor-pointer select-none font-light text-gray-700"
                              htmlFor="checkaceptar"
                            >
                              He llegit i accepto la Política d'Ús del Canal Ètic. Manifesto que la present comunicació la realitzo de bona fe i que, llevat d'error o omissió involuntària, les dades consignades són certes.
                        </label>                    
                    </div>


                    <div className='mt-6'>
                        <ReCAPTCHA
                          className='mb-2'
                          size="normal"
		                  sitekey="6Le-kbwpAAAAAJ-OCO0e8DS33fhBhIx6nztruX0Q"
		                  onChange={handleCaptchaChange}
                        />
                        <button
                          data-variant="flat"
                          className="text-[13px] md:text-sm leading-4 rounded-md inline-flex items-center cursor-pointer transition ease-in-out duration-300 font-semibold font-body text-center justify-center border-0 border-transparent placeholder-white focus-visible:outline-none focus:outline-none  bg-customRed text-white px-5 md:px-6 lg:px-8 py-4 md:py-3.5 lg:py-4 hover:text-white hover:opacity-45 hover:shadow-cart h-10 mt-1 text-sm lg:text-base w-full sm:w-auto"
                          type="submit"
                        >
                          Enviar
                        </button>
                        {
                            warning && <p className='text-red-500 mt-5'>Falten camps obligatoris</p>
                        }
                        {
                            (loading) && <Loading/>
                        }
                        
                    </div>                
                </div>
                </form>
            }
        </div>        

    </React.Fragment>
  );
}

export default Presentar;
