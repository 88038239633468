import React from 'react';

const Denuncia = ({denuncia, resetDenuncia}) => {

  return (
    <React.Fragment>
        <div className="overflow-x-auto relative my-10">
            <div className='font-light text-gray-700 mb-5'>
                <p>Comunicació d'infracció enviada.</p>
                <p>Identificador: <span className='font-bold'>{denuncia}</span></p>
                <p>Moltes gràcies</p>
            </div>
            <div>
                <a href="https://dev.fcf.cat/compliance" target="_blank" rel="noopener noreferrer">
                    <button
                        onClick={() => {                          
                            // Establecer denuncia en null
                            resetDenuncia();
                          }}
                        data-variant="flat"
                        className="text-[13px] md:text-sm leading-4 rounded-md inline-flex items-center cursor-pointer transition ease-in-out duration-300 font-semibold font-body text-center justify-center border-0 border-transparent placeholder-white focus-visible:outline-none focus:outline-none  bg-customRed text-white px-5 md:px-6 lg:px-8 py-4 md:py-3.5 lg:py-4 hover:text-white hover:opacity-45 hover:shadow-cart h-10 mt-1 text-sm lg:text-base w-full sm:w-auto"
                        type="button"
                    >
                        Tornar
                    </button>
                </a>
            </div>
        </div>     
    </React.Fragment>
  );
}

export default Denuncia;
