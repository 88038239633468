import React from 'react';
import { BiCheckCircle, BiHourglass, BiDownload  } from "react-icons/bi";

const TablaDenuncia = ({denuncia}) => {

    //Función para formatear la fecha
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
      };

  return (
    <React.Fragment>
        <div className="overflow-x-auto relative shadow-md sm:rounded-lg mt-10">
            <table className="w-full text-md text-left text-gray-500 dark:text-gray-400">
                <thead className="text-md text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" className="py-3 px-6">Petició de Comunicació infracció</th>
                </tr>
                </thead>
                <tbody>
                {denuncia.map((item, index) => (
                  <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <td className="py-4 px-6">
                      <p><span className='font-bold'>Data: </span>{formatDate(item.fecha)}</p>
                      <p className='flex flex-row items-center'><span className='font-bold mr-2'>Estat: </span>{(item.estado === 1) ? 
                                                <span title="Resolució"><BiCheckCircle/></span> : 
                                                <span title ="Pendent"><BiHourglass /></span>}
                      </p>
                      <p><span className='font-bold'>Contingut Denuncia: </span>{item.descripcion}</p>
                      {
                        item.fichero !== '' && 
                          <p className='flex flex-row items-center'>
                            <span className='font-bold mr-1'>Adjunt Denuncia: </span> 
                            <a className='underline hover:text-customRed' href={item.fichero} download={`adjunt${item.hash}`} rel="noopener noreferrer">Descarrega el fitxer adjunt</a>
                            <span className='font-bold ml-1'><BiDownload /></span>
                          </p>
                      }
                      {
                        item.respuesta !== null && <p><span className='font-bold'>Resposta: </span>{item.respuesta}</p>
                      }
                      {
                        (item.adjunto)  && 
                          <p className='flex flex-row items-center'>
                            <span className='font-bold mr-1'>Adjunt Resposta: </span> 
                            <a className='underline hover:text-customRed' target='_blank' href={item.adjunto} download={`adjunt${item.hash}`} rel="noopener noreferrer">Descarrega el fitxer adjunt</a>
                            <span className='font-bold ml-1'><BiDownload /></span>
                          </p>
                      }
                    </td>                    
                  </tr>
                ))}
                </tbody>
            </table>
            </div>     
    </React.Fragment>
  );
}

export default TablaDenuncia;
