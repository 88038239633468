import React, { useState } from 'react';
import { BiBarcode } from "react-icons/bi";
import Loading from '../../components/Loading';
import TablaDenuncia from './tabla_denuncia';

const Consultar = () => {
    //-----------------Componente loading---------------
    const [loading, setLoading] = useState(false);
    //-----------------Gestión datos--------------------
    const [denuncia, setDenuncia] = useState([]);
    const [id, setId] = useState('');
    const [errorId, setErrorId] = useState(false);

    //Función que se encarga de manejar el formulario
    const submitHandler = (event) => {
        event.preventDefault();
        setLoading(true);
        apiCall();        
    }

    //Función que se encarga de llamar a la API
    const apiCall = () => {
        const requestOptions = {
            method: 'GET',
        };

        fetch('https://dev-backend.fcf.services/compliance/get/' +id, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log(data);
                if (data.success) {
                    if(Array.isArray(data.result) && data.result.length === 0){ 
                        setErrorId(true);
                        setLoading(false);        
                    } else {
                        setDenuncia(data.result);
                        setErrorId(false);
                        setLoading(false);
                    }
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setErrorId(true);
                setLoading(false);
                setErrorId(false);
            });
    };

  return (
    <React.Fragment>
        <div className='mt-6'>
            <h2 className='font-bebas text-2xl mb-6 tracking-wide'>Consultar comunicació d'infracció</h2>
            <form onSubmit={submitHandler}>
                <div className='flex flex-col w-full sm:w-1/2'>
                    <div id="identificador" className="my-2 w-full sm:w-auto justify-center flex items-center rounded-md">
                        <div>
                            <div
                                class="flex items-center bg-gray-100 rounded-l-md border border-white justify-center w-12 h-12 text-gray-400 ">
                                <BiBarcode/>
                            </div>
                        </div>

                        <div class="w-full">
                            <input type="text" x-model="input1"
                                className="w-full h-12 px-4 py-1 rounded-r-md border border-gray-100 text-gray-800 focus:outline-none focus:shadow-lg focus:shadow-slate-200 duration-100 shadow-gray-100"
                                placeholder="Identificador" 
                                onChange={(e) => setId(e.target.value)}/>
                        </div>
                    </div>
                    {
                        (errorId) && <span className='font-light text-gray-700'>No hi ha resultats amb aquest identificador.</span>
                    }
                    <div className='mt-4'>
                        <button
                        data-variant="flat"
                        class="text-[13px] md:text-sm leading-4 rounded-md inline-flex items-center cursor-pointer transition ease-in-out duration-300 font-semibold font-body text-center justify-center border-0 border-transparent placeholder-white focus-visible:outline-none focus:outline-none  bg-customRed text-white px-5 md:px-6 lg:px-8 py-4 md:py-3.5 lg:py-4 hover:text-white hover:opacity-45 hover:shadow-cart h-10 mt-1 text-sm lg:text-base w-full sm:w-auto"
                        type="submit"
                        >
                        Consultar
                        </button>
                    </div>
                </div>
            </form>
            {
                (loading) ? <Loading/> :
                (denuncia.length !== 0 && errorId === false) && 
                <TablaDenuncia key={denuncia.id} denuncia={denuncia}/>
            }
        </div>        

    </React.Fragment>
  );
}

export default Consultar;
